<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>达人审核</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <div class="search-box">
                <span class="title">审核类型：</span>
                <el-select size="small" v-model="param.status" placeholder="请选择" @change="searchList()">
                    <el-option v-for="(item,index) in statusArr" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <span class="title">昵称：</span>
                <el-input size="small" placeholder="请输入昵称" v-model="param.name" @keyup.enter.native="searchList()">
                    <i slot="suffix" class="el-icon-search search-bottom" @click="searchList()"/>
                </el-input>
            </div>
            <!--------------------用户表格--------------------->
            <el-table :data="list" border v-loading="loading" element-loading-text="加载中,请稍候" :header-cell-style="{background:'#FAFAFA'}"><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 + query.number * (query.page - 1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="昵称" min-width="100"></el-table-column>
                <el-table-column prop="fullname" label="姓名" min-width="100"></el-table-column>
                <el-table-column prop="" label="性别" width="60">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sex == 0 ? '女' : '男' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="cell_phone" label="手机号"  width="120"></el-table-column>
                <el-table-column prop="birthday" label="出生日期" width="110">
                    <template slot-scope="scope">
                        <span>{{  scope.row.birthday.substring(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="height" label="身高"  width="80">
                    <template slot-scope="scope">
                        <span>{{  scope.row.height + 'cm' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="weight" label="体重"  width="80">
                    <template slot-scope="scope">
                        <span>{{  scope.row.weight + 'kg' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="star_sign" label="星座"  width="90"></el-table-column>
                <el-table-column prop="area_id" label="区域位置" min-width="100" >
                    <template slot-scope="scope">
                        <span>{{ showArea(scope.row.area_id) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="items" label="技能"  width="132">
                    <template slot-scope="scope">
                        <span class="textOver">{{ showItem(scope.row.items) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="avatar" label="头像"  width="132">
                    <template slot-scope="scope">
                        <el-image style="width: 112px;height: 80px;object-fit: cover;" :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="signature" label="个性签名" width="132">
                    <template slot-scope="scope">
                        <span class="textOver" :title="scope.row.signature"> {{ scope.row.signature }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="work" label="上下班时间" width="110">
                    <template slot-scope="scope">
                        <span> {{ scope.row.work_start + ' - ' +  scope.row.work_end }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="120" fixed="right">
                    <template slot-scope="scope" >
                        <el-button type="text" size="small" @click="showPhotos(scope.row)">生活照</el-button>
                        <el-divider direction="vertical" v-if="scope.row.status == 0"></el-divider>
                        <el-button type="text" size="small" @click="showCheck(scope.row)" v-if="scope.row.status == 0">审核</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--------------------分页组件--------------------->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="query.page" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="query.number"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
            <!-------------图片预览对话框----------------->
            <el-dialog title="生活照" :visible.sync="photoDialog" append-to-body  width="1000px"  @close="closePreview">
                <div class="photo-box">
                    <div v-if="photoArr.length == 0" style="text-align: center;width: 100%;">暂无数据</div>
                    <div v-else class="photo" v-for="(item, index) in photoArr" :key="index">
<!--                            <img v-if="item.type == 0" :src="item.url" alt=""/>-->
                        <el-image  v-if="item.type == 0" :src="item.url" :preview-src-list="previewArr">
                        </el-image>
                        <video v-if="item.type == 1" controlslist="nodownload" disablePictureInPicture muted  x5-video-player-type="h5" x5-video-player-fullscreen
                               x5-video-orientation="portraint" x-webkit-airplay playsinline x5-playsinline controls webkit-playsinline="true"
                               ref="videoRef">
                            <source type="video/mp4" :src="item.url">
                        </video>
                    </div>
                </div>
            </el-dialog>
            <!--审核对话框-->
            <el-dialog :title="'审核达人-' + currentObj.fullname" :visible.sync="checkDialog" width="592px"  append-to-body  @close="closeCheckDialog">
                <div class="dialog-content">
                    <el-form :model="checkForm" :rules="checkRules" ref="checkRuleForm" label-width="150px">
                        <el-form-item label="审核：" prop="status">
                            <el-radio v-model="checkForm.status" :label="1">通过</el-radio>
                            <el-radio v-model="checkForm.status" :label="2">不通过</el-radio>
                        </el-form-item>
                        <div v-if="checkForm.status == 1">
                            <el-form-item label="签约商家：" prop="arena_id">
                                <el-select v-model="checkForm.arena_id" size="small" filterable clearable>
                                    <el-option v-for="(item, index) in arenaArr" :key="index" :label="item.fullname" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="技能：" prop="itemIds">
                                <el-select v-model="checkForm.itemIds" placeholder="请选择" size="small" multiple @change="changeItem()">
                                    <el-option v-for="item in itemArr" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                                <br/>
                            </el-form-item>
                            <div class="skill-list">
                                <div class="skill" v-for="(item, index) in items" :key="index">
                                    <div class="text">{{ item.item }}：</div>
                                    <el-input v-model="item.price" placeholder="价格" size="small" style="width: 150px;margin-left: 10px;" maxlength="9" show-word-limit></el-input>
                                    <el-input-number  placeholder="最低消费时长" size="small" controls-position="right" v-model="item.limit_hour" :min="1" :max="100" class="hour" style="margin-left: 10px;"></el-input-number>
                                </div>
                            </div>
                            <el-form-item label="分成(1-100)：" prop="profit">
                                <el-input-number size="small" v-model="checkForm.profit" :min="1" :max="100" class="num"></el-input-number>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="checkDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitCheck('checkRuleForm')" :loading="btnLoading">保 存</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
import _ from 'lodash';
export default {
    name: "list",
    data() {
        var checkPrice = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入'))
            } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
                callback(new Error('金额格式错误'))
            } else {
                callback()
            }
        }
        return {
            param: {//请求参数
                name: '',//昵称
                status: 0,//审核类型
            },
            statusArr: [{id: 0, name: '待审核'},{id: 2, name: '不通过'}],
            list: [],//表格列表
            methodArr: [],//打法
            loading: false,//表格加载loading

            checkDialog: false,//审核弹窗
            levelArr: [],//级别
            checkForm: {
                arena_id: '',//签约商家
                status: 1,//状态 0:待审核;1:通过;2-不通过;
                itemIds: [],//技能
                profit: undefined,//分成
            },
            checkRules: {// 表单校验规则对象
                arena_id: [
                    {required: false, message: '请选择', trigger: 'change'},
                ],
                status: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                itemIds: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                profit: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
            },
            btnLoading: false,//按钮状态
            total: 0,//总条数
            query: {
                page: 1,//页码
                number: 10,//数量
            },
            currentObj: {},//当前对象
            photoDialog: false,//生活照弹窗
            photoArr: [],//生活照
            previewArr: [],//预览列表
            itemArr: [],//技能
            items: [],//提交的技能
            arenaArr: [],//签约商家选项
        }
    },
    async mounted () {
        await this.getAreaTree()//查区域
        await this.getPlayItem();  //查技能
        await this.getList();//查列表
        this.getArena();//查商家
    },
    methods: {
        //查商家
        async getArena() {
            let { data: res } = await this.$http.get('/admin/api/arena/list',)
            if (res.code == 200) {
                if(res.data) {
                    this.arenaArr = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //查技能
        async getPlayItem() {
            let data = {
                type: 'play_item',
                pageType: 0,//0不分页 1分页
            }
            let res = await this.$http.get('/admin/api/get/dict', { params: data})
            if (res.data.code == 200) {
                if(res.data.data) {
                    this.itemArr = res.data.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //修改技能
        changeItem() {
            let idLen = _.cloneDeep(this.checkForm.itemIds.length)
            let itemLen = _.cloneDeep(this.items.length)
            if(idLen > itemLen)  {//新增就按id查出新增的对象，赋值到items中
                let curId = this.checkForm.itemIds[this.checkForm.itemIds.length-1];
                let obj = null;
                let findIndex = (this.itemArr || []).findIndex((item) => item.id == curId);
                if(findIndex != -1) {
                    obj = this.itemArr[findIndex];
                }
                if(obj) {
                    this.items.push({dict_id:obj.id, item: obj.name, price: '',  limit_hour:undefined})
                }
            } else {//删除就找到删除的哪一个，items中删掉
                let idArr =  this.checkForm.itemIds;
                for(let i=0; i<this.items.length; i++) {
                    if(idArr.join(',').indexOf(this.items[i].dict_id) > -1) {//找到了
                        //console.log('找到了：', this.items[i].dict_id)
                    } else {
                        let findIndex = (this.items || []).findIndex((item) => item.id == this.items[i].dict_id);
                        this.items.splice(findIndex, 1);
                    }
                }
            }
        },
        //从第一页开始重新加载列表
        searchList() {
            this.query.page = 1
            this.query.number = 10
            this.getList();
        },
        //查列表
        async getList() {
            this.loading = true;
            let data = {
                nickname: this.param.name,
                status: this.param.status,
                page: this.query.page,
                number: this.query.number,
            }
            let { data: res } = await this.$http.get('/admin/api/get/audit/assistant', { params: data})
            //let { data: res } = await this.$http.get('/admin/api/get/assistant', { params: data})
            this.loading = false;
            if (res.code == 200) {
                if(res.data.result) {
                    this.list = res.data.result
                    this.total = res.data.total
                }
            } else {
                this.$message.error(res.message)
            }
        },
        // 监听每页显示多少条的改变
        handleSizeChange (newSize) {
            this.query.page = 1
            this.query.number = newSize
            this.getList()
        },
        // 监听选择第了几页
        handleCurrentChange (newPage) {
            this.query.page = newPage
            this.getList()
        },
        //技能显示
        showItem(item) {
            let str = '';
            if(item != null) {
                for(let i=0; i<item.length; i++) {
                    str += item[i].item + '，';
                }
            }
            return str.slice(0, str.length-1);
        },
        //查生活照
        async showPhotos(row) {
            this.currentObj = row
            this.photoDialog = true
            let res = await this.$http.get('/admin/api/get/assistant/photo', { params: {id: row.id}})
            if (res.data.code == 200) {
                if(res.data.data) {
                    let list = res.data.data
                    let newArr = []
                    let previewArr = []
                    for(let i=0; i<list.length; i++) {
                        let type = 0;//0图片1视频
                        if(list[i].photo_url.indexOf('mp4') != -1) {
                            type = 1;
                        } else {
                            previewArr.push(list[i].photo_url);
                        }
                        newArr.push({type: type, url: list[i].photo_url});
                    }
                    this.photoArr = newArr;
                    this.previewArr = previewArr;
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //关闭生活照弹窗
        closePreview() {
            this.photoArr = [];
            this.previewArr = [];
        },
        //打开审核弹窗
        showCheck(rows) {
            this.currentObj = rows
            this.checkDialog = true
            if(rows.items != null) {
                for(let i=0; i<rows.items.length; i++) {
                    this.checkForm.itemIds.push(rows.items[i].dict_id)
                    this.items.push({dict_id: rows.items[i].dict_id, item: rows.items[i].item, price: '', limit_hour: undefined})
                }
            }
        },
        //审核
        submitCheck(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {

                    // let priceCopy = _.cloneDeep(this.checkForm.price)
                    // console.log('priceCopy:', priceCopy)
                    // if(priceCopy > 999999.99) {
                    //     return this.$message.error('价格不能超过999999.99')
                    // }
                    let item = [];
                    if(this.checkForm.status == 1) {
                        let reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
                        for(let i=0; i<this.items.length; i++) {
                            if(this.items[i].limit_hour == undefined || this.items[i].limit_hour == null || this.items[i].limit_hour == '') {
                                return this.$message.error('请输入技能最低时长')
                            }
                            if(!reg.test(this.items[i].price)){
                                return this.$message.error('技能价格格式错误')
                            }
                            let priceCopy = _.cloneDeep(this.items[i].price)
                            if(priceCopy > 999999.99) {
                                return this.$message.error('技能价格不能超过999999.99')
                            }
                            item.push({dict_id:this.items[i].dict_id, item:this.items[i].item,price:parseInt((priceCopy * 100).toFixed(0)),limit_hour:this.items[i].limit_hour })
                        }
                    }
                    this.btnLoading = true;
                    let param = {
                        id: this.currentObj.id,
                        arena_id: this.checkForm.arena_id,
                        status: this.checkForm.status,
                        //level_id: this.checkForm.level_id,
                       // price: parseInt((priceCopy * 100).toFixed(0)),
                        profit: this.checkForm.profit,
                        items: item,
                    }
                    let res = await this.$http.post('/admin/api/audit/assistant', param)
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        this.closeCheckDialog();
                        await this.searchList();
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭审核弹窗
        closeCheckDialog() {
            if (this.checkForm.id) {
                delete this.checkForm.id;
            }
            this.btnLoading = false;
            this.$refs['checkRuleForm'].resetFields();
            this.checkDialog = false;
            this.photoArr = [];
            this.items = []
            this.checkForm.itemIds = []
        },
        //查区域
        async getAreaTree() {
            let { data: res } = await this.$http.get('/admin/api/area/treeList', )
            if (res.code == 200) {
                if(res.data) {
                    this.areaTree = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //显示区域名称
        showArea(areaId) {
            let str = '';
            let obj = this.fundNode(this.areaTree, areaId);
            if(obj) {
                str = obj.name;
            }
            return str;
        },
        //按id递归查出对象
        fundNode(tree, id) {
            var stark = []
            stark = stark.concat(tree)
            while (stark.length) {
                var temp = stark.shift()
                if (temp.children) {
                    stark = stark.concat(temp.children)
                }
                if (temp.id === id) {
                    return temp
                }
            }
        },

    }
}
</script>

<style scoped>
.photo-box {min-height: 300px;display: flex;flex-wrap: wrap;}
.photo {width: 224px;height: 160px;background-color: #eee;margin-right: 10px;margin-bottom: 10px;}
.photo img{width: 100%;height: 100%;object-fit: cover;}
.photo .el-image{width: 100%;height: 100%;object-fit: cover;}
.photo video {width: 100%;height: 100%;object-fit: cover;}
.textOver { display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
.skill-list {margin-bottom: 30px;margin-top: -5px;}
.skill {display: flex;align-items: center; width: 470px;margin-bottom: 12px;}
.skill .text{width: 140px;text-align: right;color: #262626;}
</style>
<style>
.photo .el-image .el-image__inner {width: 100%;height: 100%;object-fit: cover;}
.el-image-viewer__wrapper {z-index: 3000 !important;}
.el-image img {object-fit: cover;}
.num .el-input {width: 130px;}
.hour .el-input {width: 150px;}
.skill .el-input-number--small{width: 150px;}
</style>
